import BackgroundImage from "gatsby-background-image";
import Button from "@components/button";
import { Link } from "gatsby";
import Logo from "@components/logo";
import React from "react";
import Seo from "@components/seo";
import styled from "styled-components";
import { useImage } from "@queries/use-image";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const HeaderWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  height: 30px;
  width: 100%;
  display: grid;
  grid-column-gap: var(--spacing-m);
  grid-template-columns: 1fr calc(min(1110px, calc(100% - 32px))) 1fr;
`;

const HeaderContent = styled.div`
  height: 100%;
  grid-column: 2;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  grid-column: 2;
`;

const Test = styled.div`
  display: grid;
  grid-column-gap: var(--spacing-m);
  grid-template-columns: 1fr calc(min(1110px, calc(100% - 32px))) 1fr;
  height: 100%;
`;

const Title = styled.p`
  max-width: 445px;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const Subtitle = styled.p`
  max-width: 540px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 40px;
`;

const InnerMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function Home() {
  const { getFluidImageName } = useImage();

  return (
    <Container>
      <BackgroundImage
        style={{ width: "100%", height: "100%" }}
        fluid={getFluidImageName("404-background")}
        fadeIn={false}
      >
        <Test>
          <HeaderWrapper>
            <HeaderContent>
              <Logo isWhite />
            </HeaderContent>
          </HeaderWrapper>

          <MessageWrapper>
            <InnerMessageWrapper>
              <Title>erro 404 - saíste fora da treebo</Title>
              <Subtitle>
                Ooops! Acontece. Parece que a página que procuravas não está
                aqui. Pode ter sido movida, apagada, ou pode simplesmente nunca
                tert existido. Lamentamos o sucedido.
              </Subtitle>
              <Link to="/">
                <Button size="large" color="black" bg="white" border="none">
                  voltar à treebo
                </Button>
              </Link>
            </InnerMessageWrapper>
          </MessageWrapper>
        </Test>
      </BackgroundImage>
      <Seo
        title="Erro de cálculo!"
        description="Upsss... Enganaste-te num passo qualquer! Revê as tuas contas e volta a tentar :)"
        image={getFluidImageName("upsy")}
      />
    </Container>
  );
}
